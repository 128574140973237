import React from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

import TextHeading from "../TextHeading";

import "./_style.scss";

const SellingPoint = ({
  avatar,
  children,
  cite,
  cta,
  heading,
  isTestimonial,
  alignment,
}) => (
  <div
    className={
        classNames(
          {
            "selling-point": true,
            contained: true,
            "--testimonial": isTestimonial,
          },
          {
            [`--text-${alignment}`]: alignment,
          },
        )
      }
  >

    {avatar
        && (
        <div className="selling-point__avatar">
          {avatar}
        </div>
        )}
    {heading
        && (
        <div className="selling-point__heading">
          <TextHeading look="inverse" align="center" level={2} marginBottom="half" size={3}>
            {heading}
          </TextHeading>
        </div>
        )}
    <div className="selling-point__copy">{children}</div>
    {cite
        && (
        <div className="selling-point__cite">
          {cite}
        </div>
        )}
    {cta
        && (
        <div className="selling-point__cta">
          {cta}
        </div>
        )}
  </div>
);

SellingPoint.propTypes = {
  avatar: PropTypes.node,
  children: PropTypes.node.isRequired,
  cite: PropTypes.node,
  cta: PropTypes.node,
  heading: PropTypes.string,
  isTestimonial: PropTypes.bool,
};

export default SellingPoint;
